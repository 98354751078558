import React, { Component }from 'react';
import Home from './components/Home';
import About from './components/About';
import MainMenu from './components/MainMenu';
import { Grid } from '@material-ui/core';

import {BrowserRouter as Router, Route } from 'react-router-dom';

class App extends Component {
  
  constructor() {
    super();
    this.state = {
        isHidden: true
    };
  }

  toggleHidden() {
    this.setState({
        isHidden: !this.state.isHidden
    });
  }

  render() {
    return (
    <div >
      <Grid container direction="column" justify="space-between" alignItems="center">
      <div onClick={this.toggleHidden.bind(this)}>___</div> 
        <Router>
          { !this.state.isHidden && <MainMenu /> }
          <Route path='/' component={Home} exact></Route>
          <Route path='/about' component={About}></Route>
          
        </Router>
      </Grid>
    </div>      
    );
  }
}

export default App;
