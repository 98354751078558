import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Icon, Button } from '@material-ui/core';

class MainMenu extends Component {
    render() {
        return (
            <div>
                <Button color="primary" component={Link} to='/' onClick={this.goHome}>
                    <Icon>home</Icon>
                </Button>
                <Button color="primary" component={Link} to='/about' >
                    <Icon>android</Icon>
                </Button>    
            </div>
        );
    }

    goHome() {
        console.log("HOME");
    }
}

export default MainMenu;